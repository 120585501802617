//  const jobData = [
//   {
//     id: 1,
//     job: "TV Mounting/Install = 45 min -1 hour",
//     workCost: "Materials Estimate if needed $40-$60+ on average",
//     section: "border-rt",
//   },
//   {
//     id: 2,
//     job: "Fan/Light Fixture Install = 45 min -1 hour",
//     workCost: "Materials Estimate if needed $150-$200+ on average",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 3,
//     job: "Bathroom Faucet Replacement = 30-45 min ",
//     workCost: "Materials Estimate Onsite, if needed $40-60+ on average",
//     section: "border-rt",
//   },
//   {
//     id: 4,
//     job: "Kitchen Faucet Replacement = 45 min - 1 hour",
//     workCost: "Materials Estimate if needed $60+ on average",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 5,
//     job: "Garbage Disposal Replacement = 1 hour",
//     workCost: "Materials Estimate if needed $125+ on average for a Badger Brand",
//     section: "border-rt",
//   },
//   {
//     id: 6,
//     job: "Microwave Install = 30 min - 1 hour",
//     workCost: "Materials Estimate must be onsite",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 7,
//     job: "Anti-siphon = 2 hours",
//     workCost: "Materials Estimate if needed $80+ on average",
//     section: "border-rt",
//   },
//   {
//     id: 8,
//     job: "Toilet Gut Replacement = 30 Minutes - 1 Hour",
//     workCost: "Materials Estimate if needed $30+ on average",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 9,
//     job: "Toilet Replacement = 1 hour",
//     workCost: "Materials Estimate if needed $120+ on average depending on the toilet",
//     section: "border-rt",
//   },
//   {
//     id: 10,
//     job: "Outdoor Water Spigot Fix/Replacement = 1 hour",
//     workCost: "Materials Estimate if needed $5+ on average",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 11,
//     job: "Interior Door Replacement = 2 hours",
//     workCost: "Materials Estimate if needed $90+ on average",
//     section: "border-rt",
//   },
//   {
//     id: 12,
//     job: "Front/Back/Garage Access Door Replacement = 1 hour",
//     workCost: "Materials Estimate if needed $125+ on average",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 13,
//     job: "Basic Drywall Fix 'Baseball' and Under Approx = 1 hour",
//     workCost: "*Materials Estimate if needed $25+ on average",
//     section: "border-rt",
//   },
//   {
//     id: 14,
//     job: "Drywall Fix 'Baseball-Basketball' Size = 2-3 hours",
//     workCost: "*Materials Estimate if needed $40+ on average",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 15,
//     job: "Larger Drywall Fixes, 4 hours + 3 hours",
//     workCost: "Materials Estimate if needed $50+ on average",
//     section: "border-rt",
//   },
//   {
//     id: 16,
//     job: "Outlet Fix Replacement = 15 Min Smart Outlet = 45-60 Min",
//     workCost: "Materials Estimate if needed $3+ on average",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 17,
//     job: "Light Switch Replacement = 5 - 10 Min , Smart Light Switch/Dimmer Switch 30 Min",
//     workCost: "Materials Estimate if needed $3+ on average",
//     section: "border-rt",
//   },
//   {
//     id: 18,
//     job: "Washer/Dryer Install = 30 Min Vent and Plug Needed",
//     workCost: "Materials Estimate if needed $30+ on average",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 19,
//     job: "Install Ring/Chime Doorbell = 30 Min",
//     workCost: "Security = 45 Minutes Owner Needs Materials",
//     section: "border-rt",
//   },
//   {
//     id: 20,
//     job: "Ceiling Patches Under 1 foot Square = 1 hour total, * May need return visit 2ft- 3ft",
//     workCost: "Square = 3 hours Total 2 hours, patch and repair/'float' 1 hour return to sand and paint",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 21,
//     job: "Front Door Break In Repair = 2 - 3 hours",
//     workCost: "",
//     section: "border-rt",
//   },
//   {
//     id: 22,
//     job: "Front Door Break In 'Replacement' = 4+ hours",
//     workCost: "Materials Estimate if needed $200+ on average: Door Frame, Trim, Drywall, Lock, Etc.",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 23,
//     job: "Patio, Pergola, Playset, Shed Assembly,",
//     workCost: "2 People hours Listed in Instructions, Usually 4-8 hours",
//     section: "border-rt",
//   },
//   {
//     id: 24,
//     job: "Shower Faucet/Spigot Fix/Replacement = 1 Approx",
//     workCost: "Materials Estimate if needed $40+ on average",
//     section: "top-requested-second-col",
//   },
//   {
//     id: 25,
//     job: "Replace GFCI = 20 Minutes",
//     workCost: "Materials Estimate if needed $20+ in parts on average",
//     section: "border-rt",
//   },
//   {
//     id: 26,
//     job: "Overmount Sink Replacement = 1-2 hours",
//     workCost: "Depending on plumbing, garbage disposal, dishwasher 2 hours If there is filtration system or water/soap spout Materials Estimate Must be onsite",
//     section: "top-requested-second-col",
//   }
// ];

// export default jobData;

const jobData = [
  {
    id: 1,
    job: "Fan Replacement = 45 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "border-rt",
    details: "Customer typically has the fan onsite.",
  },
  {
    id: 2,
    job: 'TV Mounting (up to 65") = 45 minutes',
    workCost: "$99 (1 Hour minimum call)",
    section: "top-requested-second-col",
    details: "Customer typically has the mount onsite.",
  },
  {
    id: 3,
    job: "Faucet Replacement = 30 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "border-rt",
    details: "Materials required for installation.",
  },
  {
    id: 4,
    job: "Faucet Replacement = 1 hour",
    workCost: "$99 (1 Hour minimum call)",
    section: "top-requested-second-col",
    details: "Materials required for installation.",
  },
  {
    id: 5,
    job: "Toilet Repair = 30 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "border-rt",
    details: "Basic repair materials may be needed.",
  },
  {
    id: 6,
    job: "Toilet Replacement = 1 hour",
    workCost: "$99 (1 Hour minimum call)",
    section: "top-requested-second-col",
    details: "New toilet and installation materials required.",
  },
  {
    id: 7,
    job: "Garbage Disposal Replacement = 45 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "border-rt",
    details: "New disposal unit required.",
  },
  {
    id: 8,
    job: "Smoke Detector Replacement = 15 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "border-rt",
    details: "Materials typically onsite.",
  },
  {
    id: 9,
    job: "Air Filter Replacement = 10 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "top-requested-second-col",
    details: "Materials typically onsite.",
  },
  {
    id: 10,
    job: "Outlet Replacement = 10 minutes per outlet",
    workCost: "$99 (1 Hour minimum call)",
    section: "border-rt",
    details: "Materials required for installation.",
  },
  {
    id: 11,
    job: "Light Switch Replacement = 10 minutes per switch",
    workCost: "$99 (1 Hour minimum call)",
    section: "top-requested-second-col",
    details: "Materials required for installation.",
  },
  {
    id: 12,
    job: "GFCI Outlet Replacement = 20 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "border-rt",
    details: "Materials required for installation.",
  },
  {
    id: 13,
    job: 'Small Drywall Repair (less than 12") = 2 hour',
    workCost: "$178",
    section: "top-requested-second-col",
    details: "Materials required for repair.",
  },
  {
    id: 14,
    job: 'Medium Drywall Repair (12"-24") = 3 hours',
    workCost: "$267",
    section: "border-rt",
    details: "Materials required for repair.",
  },
  {
    id: 15,
    job: 'Large Drywall Repair (24"-48") = 4 hours',
    workCost: "$340",
    section: "top-requested-second-col",
    details: "Return visit needed for sanding and painting.",
  },
  {
    id: 16,
    job: "Full Sheet Drywall Repair = 3 hours + 2-hour return",
    workCost: "$425",
    section: "border-rt",
    details: "Return visit needed for sanding and painting.",
  },
  {
    id: 17,
    job: "Blinds Installation = 20 minutes per blind",
    workCost: "$99",
    section: "top-requested-second-col",
    details: "Materials typically onsite.",
  },
  {
    id: 18,
    job: "Pergola Assembly (2 handymen) = 4 hours Aprox.",
    workCost: "$640",
    section: "top-requested-second-col",
    details: "Materials typically onsite.",
  },
  {
    id: 19,
    job: "Shed Assembly (2 handymen) = 4 hours Aprox.",
    workCost: "$640",
    section: "border-rt",
    details: "Materials typically onsite.",
  },
  {
    id: 20,
    job: "Christmas Light Installation (Basic Setup) = 2 hours",
    workCost: "$178",
    section: "top-requested-second-col",
    details: "Materials typically onsite.",
  },
  {
    id: 21,
    job: "Christmas Light Installation (Decorative Setup) = 3 hours",
    workCost: "$267",
    section: "border-rt",
    details: "Materials typically onsite.",
  },
  {
    id: 22,
    job: "Christmas Light Installation (Large Setup, 1 handyman) = 4-6 hours Aprox.",
    workCost: "$85/hr",
    section: "top-requested-second-col",
    details: "Materials typically onsite.",
  },
  {
    id: 23,
    job: "Christmas Light Installation (Large Setup, 2 handymen) = 4-6 hours Aprox.",
    workCost: "$160/hr",
    section: "border-rt",
    details: "Materials typically onsite.",
  },
  {
    id: 24,
    job: "Door Replacement (Standard) = 2 hours",
    workCost: "$178",
    section: "top-requested-second-col",
    details: "Door and hardware required for installation.",
  },
  {
    id: 25,
    job: "Full Door Replacement (frame, trim, paint) = 6 hours",
    workCost: "$85/hr = $510",
    section: "border-rt",
    details: "Door, frame, and materials required.",
  },
  {
    id: 26,
    job: "Door Hardware Replacement = 30 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "top-requested-second-col",
    details: "New hardware required for installation.",
  },
  {
    id: 27,
    job: "Painting Services = Varies based on sq ft",
    workCost: "$1.50 per sq ft + cost of material",
    section: "border-rt",
    details: "Paint and supplies required.",
  },
  {
    id: 28,
    job: "Water Heater Replacement = Flat rate",
    workCost: "$600",
    section: "top-requested-second-col",
    details: "New water heater required. Includes disposal of old unit.",
  },
  {
    id: 29,
    job: "Spigot Replacement = 30 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "border-rt",
    details: "Replacement parts required.",
  },
  {
    id: 30,
    job: "Furniture Assembly = Based on instructions",
    workCost: "Varies",
    section: "top-requested-second-col",
    details:
      "Time estimate based on manufacturer's directions. Typically 4 hours for 2 people for complex items.",
  },
  {
    id: 31,
    job: "Washer/Dryer Installation = 30 minutes",
    workCost: "$99 (1 Hour minimum call)",
    section: "border-rt",
    details: "Basic installation materials may be needed.",
  },
  {
    id: 32,
    job: "Built-in Microwave Replacement = 2 hours",
    workCost: "$178",
    section: "top-requested-second-col",
    details: "New microwave required for installation.",
  },
  {
    id: 33,
    job: "Tile Installation (Standard) = Varies",
    workCost: "$5.50 per sq ft for tile",
    section: "border-rt",
    details: "Tiles and installation materials required.",
  },
  {
    id: 34,
    job: "Flooring Installation (Luxury Vinyl Plank Flooring) = Varies",
    workCost: "$3.50 per sq ft",
    section: "top-requested-second-col",
    details: "Flooring materials and underlayment required.",
  },
];

export default jobData;
